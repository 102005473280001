import React, { useEffect } from "react";
import { Card, Typography, Button } from "@material-tailwind/react";
import "./lesson.css";
import { useNavigate } from 'react-router-dom';


function Lesson({ onScroll }) {

  const navigate = useNavigate();

  useEffect(() => {
    onScroll(true);
    window.scrollTo(0, 0);
  }, []);

  const handleNavigation = () => {
    navigate("/contact"); // Navigate to the desired route
  };

  return (
    <div className="lesson-container">
      {/* Header Section */}
      <header className="lesson-header">
        <div className="header-overlay">
          <Typography variant="h2" className="lesson-title">
            Private Mathematics & Physics Tutoring
          </Typography>
          <Typography className="lesson-subtitle">
            Personalized lessons in Brussels.
          </Typography>
          {/* <Button
            variant="gradient"
            className="header-button"
            onClick={() => alert("Booking Coming Soon!")}
          >
            Book a Session
          </Button> */}
        </div>
      </header>

      {/* About Section */}
      <section className="lesson-about">
        <Typography variant="h4" className="section-title">
          Why Learn with Me?
        </Typography>
        <div className="lesson-cards">
          <Card className="lesson-card">
            <Typography variant="h5" className="card-title">
              Individual Focus
            </Typography>
            <Typography>
              I provide personalized tutoring to ensure you grasp even the most complex topics.
            </Typography>
          </Card>
          <Card className="lesson-card">
            <Typography variant="h5" className="card-title">
              Proven Expertise
            </Typography>
            <Typography>
              My engineering background ensures that I deliver in-depth and accurate instruction.
            </Typography>
          </Card>
          <Card className="lesson-card">
            <Typography variant="h5" className="card-title">
              Flexible Scheduling
            </Typography>
            <Typography>
              I accommodate your schedule to make learning stress-free.
            </Typography>
          </Card>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="lesson-pricing">
        <Typography variant="h4" className="section-title">
          Affordable Pricing
        </Typography>
        <div className="pricing-cards">
          <Card className="pricing-card">
            <Typography variant="h5" className="card-title">
              Individual Lessons
            </Typography>
            <Typography>€20/hour</Typography>
          </Card>
          <Card className="pricing-card">
            <Typography variant="h5" className="card-title">
              Group Sessions
            </Typography>
            <Typography>€10/hour per person (up to 3 people)</Typography>
          </Card>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="lesson-contact">
        <Typography variant="h4" className="section-title">
          Start Learning Today
        </Typography>
        <Typography>
          Ready to excel? Let's make mathematics and physics your strengths.
        </Typography>
        <Button
          variant="gradient"
          className="contact-button"
          // onClick={() => alert("Contact Form Coming Soon!")}
          onClick={handleNavigation}
        >
          Contact me
        </Button>
      </section>
    </div>
  );
}

export default Lesson;
