// import packages
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie';

// import data, images and other things
import imageAbove from './test1.svg';
import avatar from './profile2.png';
import background_aboutme from './aboutme_back.svg';
import paperPlaneAnimation from './Paper.json';

import './home.css';


function Home({ onScroll }) {

  // State for the Lottie animation
  const [isPlaying, setIsPlaying] = useState(true);
  const [paperPlanes, setPaperPlanes] = useState([]);

  const navigate = useNavigate();

    // Fonction pour calculer les positions en fonction de la taille de l'écran
    const calculatePaperPlanes = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      if(width <= 480) {
        return [
          { id: 1, x: width * 0.7, y: height * 0.25, size: 80, delay: 0.5 },
        ];
      }

      return [
        { id: 1, x: width * 0.1, y: height * 0.8, size: 80, delay: 0.2 },
        { id: 2, x: width * 0.7, y: height * 0.4, size: 80, delay: 0.5 },
        { id: 3, x: width * 0.1, y: height * 0.2, size: 80, delay: 1.1 },
      ];
    };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: paperPlaneAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };


  useEffect(() => {
    onScroll(false);
    const handleScroll = () => {
      if (window.scrollY > 150) {
        onScroll(true);
      } else {
        onScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const updatePlanes = () => setPaperPlanes(calculatePaperPlanes());
    updatePlanes(); // Initialisation au chargement
    window.addEventListener('resize', updatePlanes); // Écoute les changements de taille d'écran

    return () => window.removeEventListener('resize', updatePlanes); // Nettoyage
  }, []);

  const handleStartGame = () => {
    setIsPlaying(false);
    navigate('/game');
  }


  return (
    <div className='home-container'>
      <Helmet>
        <title>YousLab: Home page</title>
      </Helmet>

      {/* Image above the page */}
      <div className="image-container">
        <div className="color-bar"></div>

        {paperPlanes.map((plane) => (
          <motion.div
            key={plane.id}
            className="paper-plane"
            style={{
              position: 'absolute',
              left: plane.x,
              top: plane.y,
              width: `${plane.size}px`,
              height: `${plane.size}px`,
            }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: plane.delay,
              ease: 'easeOut',
            }}
          >
            <div className="paper-plane-container" onClick={handleStartGame}>
            <span className="plane-text">Click to slash it</span>
            <Lottie options={defaultOptions} height="100%" width="100%" />
            </div>
          </motion.div>
        ))}




        <img src={imageAbove} alt="Background Above" className="responsive-image" />
        <div className="overlay-content">

          <img src={avatar} alt="Avatar" className="avatar-image" />
          <h3 className='hello-text'>Hello I'm</h3>
          <h1 className="welcome-text">Yousre Ouali</h1>
          <h2 className='introduction-text'>IT Industrial Engineer</h2>
        </div>
      </div>

      {/* About Me Section */}
      <div className="about-me">

        <motion.img
          src={background_aboutme}
          alt="Background"
          className="responsive-image2"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5, ease: "easeOut" }}
        />

        <motion.div
          className="about-text"
          initial={{ opacity: 0, y: 50 }} // Animation d'apparition
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          whileHover={{ scale: 1.05, boxShadow: "15px 15px 30px rgba(0, 0, 0, 0.4)" }} // Effet au survol
        >
          {/* Effet interactif sur ABOUT ME */}
          <motion.h1
            className="about-title"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
            whileHover={{
              scale: 1.1,
              rotate: 3,
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
          >
            ABOUT ME
          </motion.h1>

          <motion.p
            className="about-content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
          >
            Hi, I'm <strong>Yousre Ouali</strong>, a passionate software developer dedicated to creating innovative and practical solutions.
            I graduated as an <strong>industrial software engineer</strong> in 2024 from HE2B ISIB in Belgium.
            During my studies, I completed two internships at <strong>Alstom</strong> and <strong>Irisib</strong>.
            These experiences equipped me with a robust skill set in collaborative and individual work environments.
          </motion.p>
        </motion.div>
      </div>




      {/* Portfolio's Purpose */}
      <div className="purpose_section">
        <motion.div
          className="purpose-text"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 20px rgba(231, 209, 146, 0.8)" }}
        >
          <h1 className="purpose-title">Portfolio's Purpose</h1>
          <p className="purpose-content">
            This portfolio showcases my skills and projects dynamically and interactively.
            Unlike a static resume, it provides a platform to demonstrate my proficiency in web development and design.
          </p>
        </motion.div>
      </div>






    </div>
  );
}

export default Home;
