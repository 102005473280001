import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';

import background_game from "./back_game.svg";
import warrior_chill from "./warrior_idle.png";
import warrior_idle from "./warrior_idle_attack.png";
import warrior_slash from "./warrior_slash.png";
import paperPlaneAnimation from "./PaperPlane.json";

import './game.css';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: paperPlaneAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const PaperPlane = ({ position, onDestroy, onReachEdge, speed }) => {
  const [x, setX] = useState(position[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setX(prevX => {
        const newX = prevX + speed; // Move the plane to the right
        if (newX > window.innerWidth) {
          onReachEdge(); // Trigger when the plane reaches the right edge
        }
        return newX;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [onReachEdge, speed]);

  const handleClick = () => {
    onDestroy(); // Remove the plane when clicked
  };

  return (
    <div
      onClick={handleClick}
      className="paperSize"
      style={{
        position: 'absolute',
        left: x,
        top: position[1],
      }}
    >
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  );
};


function GameScreen({ onScroll }) {
  const [planes, setPlanes] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [destroyedPlanesCount, setDestroyedPlanesCount] = useState(0);
  const [spawnInterval, setSpawnInterval] = useState(1000); // Temps de spawn initial
  const [planeSpeed, setPlaneSpeed] = useState(5); // Vitesse initiale

  const [warriorImage, setWarriorImage] = useState(warrior_idle);

  const [warriorImagePath, setWarriorImagePath] = useState('/warrior_idle_attack.png');

  const [classnameIdle_Slash, setClassnameIdle_Slash] = useState('warrior idle');


  useEffect(() => {
    document.body.classList.add('hide-footer');
    return () => {
      document.body.classList.remove('hide-footer');
    };
  }, []);

  useEffect(() => {
    if (!isPlaying) return;

    const interval = setInterval(() => {

      // const newPlane = { id: Date.now(), position: [0, 100 + Math.random() * 400] };

      // setPlanes(prevPlanes => [...prevPlanes, newPlane]);
      setPlanes(prevPlanes => {
        if (prevPlanes.length < 10) { // Limite à 10 avions
          const newPlane = { id: Date.now(), position: [0, getRandomYPosition()] };
          return [...prevPlanes, newPlane];
        }
        return prevPlanes;
      });

    }, spawnInterval); // Utilise la fréquence de spawn dynamique

    return () => clearInterval(interval);
  }, [isPlaying, spawnInterval]);


  useEffect(() => {
    // Ajuster la vitesse et la fréquence en fonction du nombre d'avions détruits
    if (destroyedPlanesCount >= 10) {
      setPlaneSpeed(8); // Accélérer l'avion
      setSpawnInterval(700); // Diminuer l'intervalle pour plus de spawn
    }
    if (destroyedPlanesCount >= 20) {
      setPlaneSpeed(12);
      setSpawnInterval(500);
    }
    if (destroyedPlanesCount >= 30) {
      setPlaneSpeed(16);
      setSpawnInterval(300);
    }
  }, [destroyedPlanesCount]);

  const removePlane = (id) => {
    setPlanes(prevPlanes => prevPlanes.filter(plane => plane.id !== id));
    setDestroyedPlanesCount(prevCount => prevCount + 1); // Incrémenter le compteur

    // Affiche l'image de l'attaque temporairement
    setClassnameIdle_Slash('warrior slash');
    setWarriorImage(warrior_slash);
    setTimeout(() => {
      setClassnameIdle_Slash('warrior idle');
      setWarriorImage(warrior_idle);
    }, 200); // Durée de l'animation


  };

  const startGame = () => {
    setIsPlaying(true);
    setDestroyedPlanesCount(0); // Réinitialiser le compteur au début du jeu
    setPlaneSpeed(5); // Réinitialiser la vitesse
    setSpawnInterval(1000); // Réinitialiser l'intervalle de spawn
  };

  const handlePlaneReachEdge = () => {
    setIsPlaying(false);
    setPlanes([]);
  };

  const getRandomYPosition = () => {
    const screenHeight = window.innerHeight;
    const minY = screenHeight * 0.2; // Par exemple, 10% de l'écran pour éviter les bords
    const maxY = screenHeight * 0.8; // 90% pour éviter la zone du bas
    return Math.random() * (maxY - minY) + minY;
  };


  // onScroll(true);

  return (
    <div className="backgroundGame">
      <Helmet>
        <title>YousLab: My Game</title>
      </Helmet>

      {/* put the background image here */}
      <img src="/back_game.svg" alt="Background Above" className="main-background" />

      {!isPlaying && (
        <div>
          <img src={warrior_chill} alt="Warrior chilling" className="warrior-chill" />
          <button onClick={startGame} className="start-button">
            ▶ Start
          </button>
        </div>

      )}
      {isPlaying && (
        <div className="gameArea" style={{ width: '100vw', height: '100vh' }}>
          <div className="counter">Destroyed Planes : {destroyedPlanesCount}</div>
          {planes.map((plane) => (
            <PaperPlane
              key={plane.id}
              position={plane.position}
              onDestroy={() => removePlane(plane.id)}
              onReachEdge={handlePlaneReachEdge}
              speed={planeSpeed}
            />
          ))}

          {/* <img src={warriorImagePath} alt="Warrior" className={classnameIdle_Slash} /> */}
          <img src={warriorImage} alt="Warrior" className={classnameIdle_Slash} />
          {/* <Warrior isAttacking={isAttacking} /> */}
        </div>
      )}
    </div>
  );
}


export default GameScreen;
