"use client"

import { motion, useScroll, useTransform } from "framer-motion"
import { useEffect, useState } from "react"
import { useRef } from "react";
import { getProjects } from "../../model/projects/projects";


// Génère des valeurs aléatoires pour la taille et la position des blobs
const generateBlobs = (count: number) => {
  return Array.from({ length: count }).map(() => ({
    size: Math.random() * 150 + 100, // Taille entre 100px et 250px
    duration: Math.random() * 3 + 4, // Durée entre 4s et 7s
    delay: Math.random() * 2, // Délais aléatoire
    yRange: Math.random() * 30 + 10, // Oscillation verticale
    blur: Math.random() * 20 + 10, // Flou pour l'effet de verre
    opacity: Math.random() * 0.3 + 0.2, // Opacité faible
  }))
}

function ProjectItem({ project, index }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ["start end", "center center"] });
  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <motion.div ref={ref} className="project-item" style={{ y, opacity }}>
      {/* <motion.img
        src={`/projects/${project.image}`}
        alt={project.name}
        className="project-image"
        whileHover={{ scale: 1.05, rotate: 1 }}
      /> */}
      <motion.div className="project-content" whileHover={{ scale: 1.02 }}>
        <h2 className="project-title">{project.name}</h2>
        <p className="project-year">Year: {project.year}</p>
        <p className="project-description">{project.abstract}</p>
      </motion.div>
    </motion.div>
  );
}

export default function Projects({ onScroll }) {
  const [blobs, setBlobs] = useState(generateBlobs(5)) // Nombre de blobs
  const [projects, setProjects] = useState([]);
  onScroll(true);

  // Regénérer les blobs si la taille de l'écran change
  useEffect(() => {
    const handleResize = () => setBlobs(generateBlobs(5))
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []);

  useEffect(() => {
    async function fetchProjects() {
      try {
        const data = await getProjects();
        setProjects(data.projects);
      } catch (error) {
        console.error("Error loading projects:", error);
      }
    }
    fetchProjects();
  }, []);

  return (
    <div className="projects-container">


      <motion.div
        className="intro"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1>My Academic Projects</h1>
        <p>These projects were carried out during my studies to deepen my skills in development and design.</p>
      </motion.div>


      {projects.map((project, index) => (
        <ProjectItem key={index} project={project} index={index} />
      ))}



      <div className="blob-container">
        {blobs.map((blob, index) => (


          <motion.div
            key={index}
            className="blob"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: [0, blob.opacity, 0],
              x: [Math.random() * 100 + "vw", Math.random() * 100 + "vw"],
              y: [Math.random() * 100 + "vh", Math.random() * 100 + "vh"],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: blob.duration,
              repeat: Infinity,
              ease: "easeInOut",
              delay: blob.delay,
            }}
            style={{
              width: blob.size,
              height: blob.size,
              filter: `blur(${blob.blur}px)`,
              borderRadius: "50%",
              position: "absolute",
              border: "2px solid rgb(4, 102, 0)",
              background: "#E7D192",
            }}
          />





        ))}
      </div>





      <style>{`
                .blob-container {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: #8F0D0D;
                    overflow: hidden;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                .projects-container {
                    margin-top: 13%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                }
                

                .intro {
          text-align: center;
          margin-bottom: 30px;
          margin-top: -80px;
          color: white;
        }

        .intro h1 {
          font-size: 2.5rem;
          margin-bottom: 10px;
        }

        .intro p {
          font-size: 1.2rem;
        }


                .project-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    gap: 20px;
                    background: rgb(255, 255, 255);
                    padding: 20px;
                    border-radius: 15px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
                    max-width: 800px;
                    width: 100%;
                }
                .project-image {
                    width: 200px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 10px;
                }
                .project-content {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    text-align: left;
                }
                .project-title {
                    font-size: 1.8rem;
                    font-weight: bold;
                }
                .project-year {
                    font-size: 1rem;
                    color: #888;
                }
                .project-description {
                    font-size: 1rem;
                    margin-top: 10px;
                }


                @media screen and (max-width: 855px) {

                  .projects-container {
                    margin-top: 10%;
                    margin-bottom: 15%;
                  }
                  .project-item {
                    flex-direction: column;
                    padding: 15px;
                    max-width: 90%;
                  }

                }

                @media screen and (max-width: 500px) {

                  .projects-container {
                      margin-top: 35%;
                      margin-bottom: 5%;
                    }
                    .project-title {
                      font-size: 1.4rem;
                    }
                    .project-description {
                      font-size: 0.9rem;
                    }

                }

            `}</style>
    </div>
  )
}
