import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// NAVIGATION
import Navigation from '../view/navigation/navigation';

// FOOTER
import Footer from '../view/footer/footer';

// ALL CORE VIEWS
import Home from '../view/home/home';
import Game from '../view/game/game';
import Projects from '../view/projects/projects';
import Skills from '../view/skills/skills';
import Contact from '../view/contact/contact';
import NotFound from '../view/NotFound/notFound';
import Activities from '../view/activities/activities';
import Lesson from '../view/activities/lesson/lesson';

function Controller() {
  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <BrowserRouter>
      {/* Pass isScrolled as a prop to Navigation */}
      <Navigation isScrolled={isScrolled} />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home onScroll={setIsScrolled} />} />
        <Route path="/game" element={<Game onScroll={setIsScrolled} />} />
        <Route path="/projects" element={<Projects onScroll={setIsScrolled} />} />
        <Route path="/skills" element={<Skills onScroll={setIsScrolled}/>} />
        <Route path="/contact" element={<Contact onScroll={setIsScrolled}/>} />
        <Route path="/activities" element={<Activities onScroll={setIsScrolled}/>} />
        <Route path="/activities/lesson" element={<Lesson onScroll={setIsScrolled} />} />
        <Route path="*" element={<NotFound onScroll={setIsScrolled}/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Controller;